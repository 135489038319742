import React, { useEffect, useState } from 'react';
import { Block } from '../../components/PageElements';
import { Button, Dropdown } from 'antd';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { getNotificationById, getNotifications } from '../../utils/httpServices/global';
import Card from './card';

export const orderList = [
  { label: 'Сначала непрочитанные', key: 'state' },
  { label: 'Сначала новые', key: 'createdAt' },
];

export default function NotifyPanel() {
  const [selected, setSelected] = useState<any>(null);
  const [order, setOrder] = useState<any>(orderList[0]);
  const [showSelected, setShowSelected] = useState(false);

  const { data: notificationsData, refetch } = useQuery(['notify', order?.key], () =>
    getNotifications(order?.key),
  );
  const notifications = notificationsData?.items;

  const handleCardClick = (data: any) => {
    setShowSelected(true);
    setSelected(data);
    if (data?.id) getNotificationById(data.id).then(refetch);
  };

  useEffect(() => {
    if (notifications?.length && !selected) {
      setSelected(notifications[0]);
    }
    if (notifications?.length && selected) {
      const sel = notifications.find((el: any) => el.id === selected.id);
      if (sel) setSelected(sel);
    }
  }, [notifications]);

  return (
    <div className="mt-8 mb-4 mx-[50px] flex flex-col">
      <h1 className="text-[20px]">Уведомления</h1>
      <Block noShadow className="bg-white mt-4">
        <div className="flex gap-6">
          <div className="w-1/2">
            {!notifications?.length && <p>Нет уведомлений</p>}
            {!!notifications?.length && (
              <div className="min-w-[15rem] max-w-[20rem] mb-6 cursor-pointer">
                <Dropdown
                  menu={{
                    items: orderList,
                    onClick: (info) => setOrder(orderList.find((el) => el.key === info.key)),
                  }}
                  autoFocus
                  trigger={['click']}
                >
                  <a
                    tabIndex={0}
                    onClick={(e) => e.preventDefault()}
                    className="rounded-[6px] border px-4 py-1"
                    style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                  >
                    <div className="inline-flex items-center justify-between w-full">
                      {order?.label || '--- Выберите ---'}
                      <DownOutlined />
                    </div>
                  </a>
                </Dropdown>
              </div>
            )}
            <div className="flex flex-col rounded-[12px] overflow-hidden">
              {notifications?.map((el: any, i: number) => (
                <Card
                  onClick={() => handleCardClick(el)}
                  data={el}
                  active={selected?.id === el?.id}
                  key={i}
                />
              ))}
            </div>
          </div>
          <div className="w-1/2">
            {showSelected && selected && (
              <Block className="flex items-start gap-4">
                <button
                  className="absolute top-3 right-4"
                  onClick={() => {
                    setSelected(null);
                    setShowSelected(false);
                  }}
                >
                  <CloseOutlined />
                </button>
                <img src={'/images/mock/avatar.png'} alt="" className="w-8 h-8" />
                <div className="flex flex-col gap-1 w-full">
                  <div className="text-sm font-semibold">{selected.title}</div>
                  <div className="opacity-45">{selected.body}</div>
                  {/* <Button type="primary" className="w-fit ml-auto mt-3">
                    Action
                  </Button> */}
                </div>
              </Block>
            )}
          </div>
        </div>
      </Block>
    </div>
  );
}
