import React from 'react';
import { EditOutlined, FileTextOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
interface SettingsItemProps {
  name: string;
  onLoad: () => void;
  onView: () => void;
  canChange?: boolean;
  hasContent: boolean;
}

const SettingsItem = ({
  name,
  onLoad,
  onView,
  canChange = true,
  hasContent,
}: SettingsItemProps) => (
  <div className="w-full flex items-center justify-between">
    <div className="flex items-center gap-2">
      <FileTextOutlined />
      {name}
    </div>
    <div className="flex items-center gap-4">
      {canChange && !hasContent && (
        <button
          onClick={!!onLoad && onLoad}
          className="rounded flex items-center relative px-2 py-1 cursor-pointer bg-white gap-2"
          style={{ border: '1px solid rgba(0,0,0,.15)' }}
        >
          <UploadOutlined />
          Загрузить
        </button>
      )}
      {hasContent && (
        <div className="flex gap-4">
          <button
            onClick={!!onLoad && onLoad}
            className="rounded flex items-center relative px-2 py-1 cursor-pointer bg-white gap-2"
            style={{ border: '1px solid rgba(0,0,0,.15)' }}
          >
            <EditOutlined />
            Редактировать
          </button>
          <button
            onClick={onView}
            className="rounded flex items-center relative px-2 py-1 cursor-pointer bg-white gap-2"
            style={{ border: '1px solid rgba(0,0,0,.15)' }}
          >
            <SearchOutlined />
            Просмотреть
          </button>
        </div>
      )}
    </div>
  </div>
);

export default SettingsItem;
