import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';

import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FullscreenOutlined,
  PrinterOutlined,
  StopOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { BASE_URL, RQ_OPT } from '../../utils/consts';
import { Button, Checkbox, Dropdown, Form, Input, Modal, Pagination } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getBankAccounts,
  getClient,
  getClientsAA,
  getDoc,
  setStateDoc,
  updateDoc,
} from '../../utils/httpServices/global';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Block } from '../../components/PageElements';
import { Tag } from './Tag';
import clsx from 'clsx';
import css from './index.module.css';
import { showStatus } from './utils';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { DOCS_PATH } from '../../utils/routes_consts';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const Doc = () => {
  const maxWidth =
    window.innerWidth > window.innerHeight ? window.innerWidth * 0.45 : window.innerWidth * 0.75;

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { search } = location;
  const isPrint = search?.includes('print');

  const [form] = Form.useForm();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  // const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isEditTitleModal, setIsEditTitleModal] = useState(false);
  const [isShowSend, setIsShowSend] = useState(false);
  const [client, setClient] = useState<any>(null);

  const { data: clientsAA } = useQuery('clientsAA', () => getClientsAA(), RQ_OPT);

  const { data: dataClient } = useQuery(
    ['client', client?.id || 0],
    () => (client?.id ? getClient(client.id) : null),
    RQ_OPT,
  );

  const { data: dataAccounts } = useQuery(
    ['accounts', dataClient?.company?.id || 0],
    () => (dataClient?.company?.id ? getBankAccounts(dataClient.company.id) : []),
    RQ_OPT,
  );

  const { data: doc, refetch } = useQuery(['doc', id], () => getDoc(id), RQ_OPT);
  const link = doc?.signedLink || doc?.link;

  const handleSelect = (val: any) => {
    if (!val || !clientsAA) return;
    const cl = (Array.isArray(clientsAA) ? clientsAA : []).find((el: any) => +el.id === +val.key);
    setClient(cl || null);
  };

  useEffect(() => {
    if (doc?.customer) {
      const cl = (Array.isArray(clientsAA) ? clientsAA : []).find(
        (el: any) => +el.id === +doc.customer.id,
      );
      setClient(cl || null);
    }
  }, [doc]);

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(document.getElementById('root'), {}, onResize);

  const showPDF = useCallback(
    (isPreview: boolean, isSmall: boolean) =>
      link ? (
        <div
          className={clsx(
            'w-fit !overflow-auto h-fit',
            isPreview ? 'max-h-[90dvh]' : 'mx-auto max-h-[70vh] px-[5%]',
            isSmall && 'max-h-[60dvh]',
            isPreview && css.doc,
          )}
          id="cont"
        >
          <Document
            // className={isPreview ? clsx(css.doc, 'max-h-screen !overflow-auto') : ''}
            file={`${BASE_URL}${link}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e) => console.error(e)}
            loading={<div className="my-4">Загрузка PDF...</div>}
            error={<div className="my-4">Ошибка загрузки PDF</div>}
            // noData={<div>Ничего не найдено</div>}
            // onItemClick=
            // onLoadProgress
            // onSourceError
            // onSourceSuccess
          >
            {/* {Array.from(new Array(numPages), (el, index) => ( */}
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
              loading={null}
              // height={}
              // noData
              // onLoadSuccess
            />
            {/* ))} */}
          </Document>
          {numPages > 0 && (
            <Pagination
              className="mt-2 ml-auto w-fit"
              current={pageNumber}
              pageSize={1}
              total={numPages}
              onChange={(page) => setPageNumber(page)}
              hideOnSinglePage
              showSizeChanger={false}
            />
          )}
        </div>
      ) : null,
    [containerWidth, link, maxWidth, numPages, pageNumber],
  );

  const onDocumentLoadSuccess = (props: any) => {
    setNumPages(props?.numPages);
  };

  const handleEditTitle = () => {
    form
      .validateFields()
      .then((values) => {
        if (doc?.id && doc?.customer?.id) {
          updateDoc({ id: doc.id, name: values.title, customerId: doc.customer.id }).then(() => {
            refetch();
            setIsEditTitleModal(false);
          });
        } else {
          toast('Не удалось обновить заголовок документа', { type: 'error' });
        }
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  const handleSend = () => {
    if (doc?.id && doc?.customer?.id) {
      updateDoc({ id: doc.id, name: doc.name, customerId: doc.customer.id }).then(() => {
        setStateDoc({ id: doc.id, state: 'sent' }).then(() => navigate(DOCS_PATH));
      });
    }
  };

  const showPrinter = useCallback(
    () =>
      doc ? (
        <>
          <Button className="fixed top-4 left-4" onClick={() => navigate(-1)}>
            Назад
          </Button>
          <div className="mx-auto" id="cont">
            <Document
              file={`${BASE_URL}${link}`}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<div>Загрузка PDF...</div>}
              // noData={<div>Ничего не найдено</div>}
              // onLoadError
              // onLoadProgress
              // onSourceError
              // onSourceSuccess
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={maxWidth}
                  loading={null}
                  // height={}
                  // noData
                />
              ))}
            </Document>
          </div>
        </>
      ) : null,
    [doc, link, maxWidth, navigate, numPages],
  );

  useEffect(() => {
    if (doc?.name) {
      form.setFieldValue('title', doc.name.replace(/.pdf/i, ''));
    }
    // const container = document.getElementById('cont');
    // if (container) {
    // setContainerRef(container);
    // }
  }, [doc]);

  useEffect(() => {
    if (isPrint && numPages) {
      setTimeout(() => {
        window.print();
      }, 999);
    }
  }, [isPrint, numPages]);

  return isPrint ? (
    showPrinter()
  ) : (
    <div className="my-4 mx-[50px] flex flex-col">
      <div className="flex w-full justify-between items-center mb-4">
        <div>
          <button
            onClick={() => navigate(DOCS_PATH)}
            className="opacity-45 w-fit flex gap-1 items-center select-none"
          >
            <ArrowLeftOutlined />
            Назад
          </button>
          <div className="w-full flex items-center gap-2 mb-[6px]">
            <h1 className="text-[20px]">
              {isShowSend ? 'Отправка документа' : doc?.name?.replace(/.pdf/i, '')}
            </h1>
            <EditOutlined
              style={{
                fontSize: 24,
                display: doc?.state === 'draft' && !isShowSend ? 'block' : 'none',
              }}
              role="button"
              tabIndex={0}
              onClick={() => setIsEditTitleModal(true)}
            />
          </div>
          <div className="flex">
            {(doc?.state === 'sent' ||
              doc?.state === 'draft' ||
              doc?.state === 'signed' ||
              doc?.state === 'revoked') && (
              <div className="flex gap-1 items-center mr-5">
                {isShowSend ? (
                  doc?.name?.replace(/.pdf/i, '')
                ) : (
                  <>
                    Получатель:{' '}
                    <div className="text-[#1677FF] underline">{doc?.customer?.name}</div>
                  </>
                )}
              </div>
            )}
            <div
              className="flex items-center gap-2 text-[#FF4D4F] w-fit"
              role={doc?.state === 'revoked' ? 'none' : 'button'}
              tabIndex={doc?.state === 'revoked' ? -1 : 0}
              style={{ display: isShowSend ? 'none' : 'flex' }}
            >
              {doc?.state === 'revoked' && <>Документ отозван</>}
              {doc?.state === 'sent' && (
                <>
                  <StopOutlined />
                  Отозвать
                </>
              )}
              {doc?.state === 'draft' && (
                <>
                  <DeleteOutlined />
                  Удалить
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center">
          {isShowSend ? (
            <div className="bg-[#E6F4FF] p-4 rounded-[10px] flex flex-col gap-3">
              <Button type="primary" className="w-fit ml-auto" onClick={handleSend}>
                Отправить
              </Button>
              <div className="flex items-center gap-2">
                <Checkbox />
                <p>Уведомить по WhatsApp</p>
              </div>
            </div>
          ) : (
            (doc?.state === 'draft' || doc?.state === 'sent' || doc?.state === 'signed') && (
              <Button
                type="primary"
                className="min-h-10 select-none flex gap-2 items-center"
                style={doc?.state === 'revoked' ? { display: 'none' } : {}}
                onClick={() => {
                  if (doc?.state === 'draft' && !isShowSend) {
                    setIsShowSend(true);
                  }
                  if ((doc?.state === 'sent' || doc?.state === 'signed') && doc?.id) {
                    navigate(`/doc/${doc.id}?print`);
                  }
                }}
              >
                {doc?.state === 'draft' && <>Отправить документ</>}
                {(doc?.state === 'sent' || doc?.state === 'signed') && (
                  <>
                    <PrinterOutlined className="mr-2" />
                    Печать
                  </>
                )}
              </Button>
            )
          )}
          <Button
            type="primary"
            className="h-10"
            onClick={() => {
              if (link) {
                window.open(`${BASE_URL}${link}`, '_blank');
              }
            }}
          >
            <DownloadOutlined className="mr-2" />
            Скачать
          </Button>
        </div>
      </div>
      {isShowSend ? (
        <Block noShadow className="bg-white">
          <div className="grid grid-cols-2 gap-6">
            <div className="w-full flex flex-col">
              <h3>Выберите получателя</h3>
              <Dropdown
                menu={{
                  items: (Array.isArray(clientsAA) ? clientsAA : []).map((el: any) => ({
                    label: el.name,
                    key: el.id,
                  })),
                  onClick: handleSelect,
                }}
                trigger={['click']}
                className="mt-4 mb-5 max-w-[25rem]"
              >
                <a
                  tabIndex={0}
                  onClick={(e) => e.preventDefault()}
                  className="rounded-[6px] border px-4 py-1"
                  style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                >
                  <div className="inline-flex items-center justify-between w-full">
                    {client?.name || 'Список клиентов'}
                    <DownOutlined />
                  </div>
                </a>
              </Dropdown>
              {dataClient && <Block>{showStatus({ dataClient, dataAccounts })}</Block>}
            </div>
            <div className="flex flex-col gap-4">
              <h3>Предпросмотр</h3>
              <div className="p-5 bg-[#f5f5f5] rounded-[17px] ">
                <div className="w-fit mx-auto relative">
                  <button
                    className="absolute top-0 left-0 p-2 rounded-[6px] bg-white z-50 w-8 h-8 flex items-center justify-center"
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.15)',
                      display: numPages ? 'flex' : 'none',
                    }}
                    onClick={() => setIsShowPreview(true)}
                  >
                    <FullscreenOutlined />
                  </button>
                  {showPDF(true, true)}
                </div>
              </div>
            </div>
          </div>
        </Block>
      ) : (
        <Block className="bg-[#F5F5F5] min-h-[12vh]">
          {showPDF(false, false)}

          {doc?.state === 'draft' && (
            <div className="flex gap-4 absolute top-5 right-12">
              {!!doc?.markup?.signs?.length && <Tag text="Место подписи" />}
              {!!doc?.markup?.stamps?.length && <Tag text="Место печати" />}
            </div>
          )}
        </Block>
      )}

      {doc?.state === 'draft' && (
        <Modal
          centered
          closable={false}
          open={isShowPreview}
          onCancel={() => setIsShowPreview(false)}
          style={{
            minWidth: 'fit-content',
            backgroundColor: '#f5f5f5',
            padding: '20px',
            borderRadius: '17px',
          }}
          footer={null}
        >
          <CloseOutlined
            className="absolute -right-32 w-8 h-8 rounded border z-[100] bg-[#f5f5f5] flex justify-center"
            role="button"
            tabIndex={0}
            onClick={() => setIsShowPreview(false)}
          />
          <div className="h-full w-fit mx-auto">{showPDF(true, false)}</div>
        </Modal>
      )}

      <Modal
        centered
        open={isEditTitleModal}
        onCancel={() => setIsEditTitleModal(false)}
        onOk={handleEditTitle}
        okText="Сохранить"
        cancelText="Отмена"
        title="Название документа"
      >
        <Form form={form} name="basic" initialValues={{ remember: true }}>
          <Form.Item
            name="title"
            rules={[{ required: true, message: 'Введите название документа' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Doc;
