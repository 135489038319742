import axios from 'axios';

import { URL_API } from '../consts';

import axiosInstance from '.';
import MDAxiosInstance from './docsAxiosInstance';

export const authLogin = async (data) => {
  try {
    const response = await axiosInstance.post('/login', data);
    const res = response?.data;
    if (res.token) {
      sessionStorage.setItem('token', res.token);
      sessionStorage.setItem('refreshToken', res.refreshToken);
    }
    return res;
  } catch (error) {
    return { error };
  }
};

export const refreshToken = async (rt = '') => {
  try {
    const refreshTokenSS = sessionStorage.getItem('refreshToken');
    const refreshTokenLS = localStorage.getItem('refreshToken');
    const rtoken = refreshTokenSS || refreshTokenLS;
    if (!rtoken && !rt) {
      console.error('refreshToken is required');
      return { error: 'No refresh token available' };
    }
    const response = await axios.post(`${URL_API}/refresh-token`, {
      refreshToken: rt || rtoken,
    });
    const res = response?.data;
    if (res.token) {
      sessionStorage.setItem('token', res.token);
      sessionStorage.setItem('refreshToken', res.refreshToken);
    }
    return res;
  } catch (error) {
    return { error };
  }
};

export const authResetPassword = async ({ email }) => {
  try {
    const response = await axiosInstance.post('/security/password-reset', { email });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const authResetPasswordConfirm = async ({ code, password }) => {
  try {
    const response = await axiosInstance.post('/security/password-confirm', { code, password });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getUsers = async (page) => {
  try {
    if (!page) return;
    const response = await axiosInstance.get(`/users?page=${page}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getManagers = async () => {
  try {
    const response = await axiosInstance.get(`/users?criteria[role]=manager&perPage=999`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getProfile = async () => {
  try {
    const response = await axiosInstance.get(`/profile`);
    return response?.data || null;
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const getNotifications = async (sort) => {
  try {
    const response = await axiosInstance.get(`/notification?sorting[${sort}]=ASC&perPage=999`);
    return response?.data || null;
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const getNotificationById = async (id) => {
  try {
    if (!id) throw new Error('id is required');
    const response = await axiosInstance.get(`/notification/${id}`);
    return response?.data || null;
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axiosInstance.get(`/info`);
    return response?.data || null;
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const getUser = async (id) => {
  try {
    if (!id) throw new Error('id is required');
    const response = await axiosInstance.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const createUser = async (data) => {
  try {
    const response = await axiosInstance.post('/users', data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const editUser = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const createBankAccount = async (data) => {
  try {
    const response = await axiosInstance.post('/bank-account', data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getBankAccounts = async (companyId) => {
  try {
    const response = await axiosInstance.get(
      `/bank-account?criteria[companyId]=${companyId}&perPage=999`,
    );
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getBankAccount = async (id) => {
  try {
    if (!id) throw new Error('id is required');
    const response = await axiosInstance.get(`/bank-account/${id}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const editBankAccount = async (data) => {
  try {
    const response = await axiosInstance.put(`/bank-account/${data.id}`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const deleteBankAccount = async (id) => {
  try {
    const response = await axiosInstance.delete(`/bank-account/${id}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getDoc = async (id) => {
  try {
    if (!id) return;
    const response = await axiosInstance.get(`/documents/${id}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const deleteDoc = async (id) => {
  try {
    const response = await axiosInstance.delete(`/documents/${id}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const updateDoc = async (data) => {
  try {
    const response = await axiosInstance.put(`/documents/${data?.id || 0}`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const setMarkupDoc = async ({ id, data }) => {
  try {
    const response = await axiosInstance.post(`/documents/${id}/markup`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const setStateDoc = async ({ id, state }) => {
  // 'draft';  'sent';  'signed';  'revoked';  'archived';
  try {
    const response = await axiosInstance.post(`/documents/${id}/set-state`, { state });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getDocs = async (data) => {
  // state=  [draft, sent, signed, revoked]
  const { customerId, state, page = 1, perPage = 10, searchStr, type } = data;
  try {
    const encodedSearchText = encodeURIComponent(searchStr);
    const response = await axiosInstance.get(
      `/documents?page=${page}&perPage=${perPage}${
        searchStr ? `&criteria[search]=${encodedSearchText}` : ''
      }${customerId ? `&criteria[customerId]=${customerId}` : ''}${
        state ? `&criteria[state]=${state}` : ''
      }${type ? `&criteria[type]=${type}` : ''}`,
    );
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const createDoc = async (data) => {
  try {
    const response = await axiosInstance.post('/documents', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getCompanies = async (params = {}) => {
  const { perPage = 1000, page } = params;
  try {
    const response = await axiosInstance.get(
      `/companies?perPage=${perPage}${page ? `&page=${page}` : ''}`,
    );
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getCompany = async (id) => {
  try {
    if (!id) return;
    const response = await axiosInstance.get(`/companies/${id}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const createCompany = async (data) => {
  try {
    const response = await axiosInstance.post('/companies', data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const editCompany = async (data) => {
  try {
    const response = await axiosInstance.put(`/companies/${data.id}`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const deleteCompany = async (id) => {
  try {
    const response = await axiosInstance.delete(`/companies/${id}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const assignManagerToClient = async (id, data) => {
  try {
    const response = await axiosInstance.post(`/customers/${id}/assign-manager`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const assignCompanyToClient = async (id, data) => {
  try {
    const response = await axiosInstance.post(`/customers/${id}/assign-company`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

// active+archived
export const getClientsAA = async (notReducedList = false) => {
  try {
    const response0 = await axiosInstance.get(
      `/customers/active?page=1&perPage=1000&reducedList=${notReducedList ? 0 : 1}`,
    );
    if (!response0?.data?.items) return null;

    const arr = [...response0?.data?.items];
    if (response0?.data?.pagination?.pageCount > 1) {
      for (let i = 2; i <= response0?.data?.pagination.pageCount; i++) {
        const response1 = await axiosInstance.get(
          `/customers/active?page=${i}&perPage=1000&reducedList=1`,
        );
        if (response1?.data?.items) {
          arr.push(...response1?.data?.items);
        }
      }
    }
    return arr;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getClients = async (type, page, searchStr, sortByName) => {
  try {
    if (!page) return;
    const encodedSearchText = encodeURIComponent(searchStr);
    const response = await axiosInstance.get(
      `/customers/${type}?page=${page}&perPage=999${
        searchStr ? `&criteria[search]=${encodedSearchText}` : ''
      }${sortByName ? `&sorting[name]=${sortByName}` : ''}`,
    );
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getClient = async (id) => {
  try {
    if (!id) return null;
    const response = await axiosInstance.get(`/customers/${id}`);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const clientEdit = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/customers/${id}`, data);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const requestClientStamp = async (clientId) => {
  try {
    const response = await axiosInstance.post(`/customers/${clientId}/set-need-stamp`, {
      needStamp: false,
    });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const clientToArchive = async (id) => {
  try {
    const response = await axiosInstance.put(`/customers/${id}/to-archive`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const clientFromArchive = async (id) => {
  try {
    const response = await axiosInstance.put(`/customers/${id}/from-archive`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const clientVerification = async (id) => {
  try {
    if (!id) return;
    const response = await axiosInstance.post(`/customers/${id}/verification`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const clientRevokeVerification = async (id) => {
  try {
    if (!id) return;
    const response = await axiosInstance.post(`/customers/${id}/revoke-verification`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const createMD = async (documentType, content) => {
  try {
    if (!documentType) return;
    const response = await axiosInstance.post(`/policy/create`, { documentType, content });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export const getMD = async (docType) => {
  try {
    if (!docType) return;
    const response = await MDAxiosInstance.get(`/policy/${docType}`);
    return response?.data;
  } catch (error) {
    return { error };
  }
};

// // export const getOperations = async (filters) => {
// //   try {
// //     const stringifiedParams = filters
// //       ? `?${queryString.stringify({
// //           buyer: filters.buyer ? filters.buyer.id : undefined,
// //           seller: filters.seller ? filters.seller.id : undefined,
// //           limit: filters.limit,
// //           page: filters.page,
// //           sell_quarter: filters.sell_quarter || undefined,
// //           sell_year: filters.sell_year || undefined,
// //           buy_quarter: filters.buy_quarter || undefined,
// //           buy_year: filters.buy_year || undefined,
// //           kvo: filters.kvo || undefined,
// //           buy_kvo: filters.buy_kvo || undefined,
// //           from_1c: typeof filters.from_1c === 'number' ? filters.from_1c : undefined,
// //           declaration: filters.declaration || undefined,
// //           worker: typeof filters.worker === 'number' ? filters.worker : undefined,
// //         })}`
// //       : '';
// //     const response = await axiosInstance.get(`/operations/all${stringifiedParams}`);
// //     const users = response.data;
// //     return users;
// //   } catch (error) {
// //     return { error: error.response.data };
// //   }
// // };
