import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';

import {
  A4_HEIGHT_MM,
  A4_WIDTH_MM,
  BASE_URL,
  DOC_TYPES,
  MAX_PDF_SIZE_MB,
  RQ_OPT,
} from '../../utils/consts';
import { Block, BlueBox } from '../../components/PageElements';
import { Button, Dropdown, Form, Input, Pagination } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { convertPercentToMm, uid } from '../../utils/helpers';
import {
  createDoc,
  getClient,
  getClientsAA,
  getDocs,
  setMarkupDoc,
} from '../../utils/httpServices/global';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import DD from './DD2';
import { DownOutlined } from '@ant-design/icons';
import { SignType } from './types';
import clsx from 'clsx';
import css from '../Doc/index.module.css';
import { toast } from 'react-toastify';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { DOCS_PATH } from '../../utils/routes_consts';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

export default function Upload() {
  const maxWidth =
    window.innerWidth > window.innerHeight ? window.innerWidth * 0.45 : window.innerWidth * 0.75;

  const { id: extId } = useParams();

  const [selectedFile, setSelectedFile] = useState<any>('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const docRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [doc, setDoc] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const [client, setClient] = useState<any>(null);
  const [docType, setDocType] = useState<null | (typeof DOC_TYPES)[0]>(null);
  const [showObj, setShowObj] = useState<{ stamp: any[]; signature: any[] }>({
    stamp: [],
    signature: [],
  });

  const customerId = form.getFieldValue('client')?.id;
  const type = form.getFieldValue('doctype')?.type;
  const name = form.getFieldValue('title');
  const [parentContract, setParentContract] = useState<null | number>(null);

  const { data: docs } = useQuery(['docs'], () =>
    getDocs({ page, perPage: 999, type: 'contract' }),
  );
  const contracts = docs?.items?.filter((el: any) => el.type === 'contract');

  const { data: clientsAA } = useQuery('clientsAA', () => getClientsAA(), RQ_OPT);

  const { data: dataClient } = useQuery(
    ['client', client?.id || 0],
    () => getClient(client?.id || 0),
    RQ_OPT,
  );

  const companyType = dataClient?.company?.type;
  const isPhysical = companyType === 'physical';
  const isIndividual = companyType === 'individual';
  const isIP = companyType === 'legal_entity';

  const handleSelect = (val: any) => {
    if (!val || !clientsAA) return;
    const cl = (Array.isArray(clientsAA) ? clientsAA : []).find(
      (el: any) => el?.id && +el.id === +val.key,
    );
    setClient(cl || null);
    form.setFieldValue('client', cl || '');
    if (cl) {
      form.setFields([{ name: 'client', errors: [] }]);
    }
  };

  useEffect(() => {
    if (extId) {
      handleSelect({ key: extId });
    }
  }, [extId, clientsAA]);

  const handleSelectDocType = (val: any) => {
    if (!val) return;
    const dt = DOC_TYPES.find((el: any) => el.type === val.key);
    setDocType(dt || null);
    form.setFieldValue('doctype', dt || '');
    if (dt) {
      form.setFields([{ name: 'doctype', errors: [] }]);
    }
  };

  const handleSelectParent = (val: any) => {
    form.setFieldValue('parentId', val?.key || '');
    setParentContract(+val?.key || null);
  };

  useEffect(() => {
    handleSelectParent(null);
    form.setFieldValue('number', '');
  }, [type]);

  const handleSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        // if (doc?.id && client?.id) {
        if (doc?.id) {
          // const updData = {
          //   id: doc.id,
          //   name: values.title,
          //   customerId: client.id,
          //   type: companyType || '',
          // };
          // await updateDoc(updData);

          setMarkupDoc({
            id: doc.id,
            data: {
              signs: showObj.signature.map((el) => ({
                page: el.page,
                offset: {
                  x: Math.round(convertPercentToMm(el.xPercent, A4_WIDTH_MM)),
                  y: Math.round(convertPercentToMm(el.yPercent, A4_HEIGHT_MM)),
                },
                width: Math.round(convertPercentToMm(el.widthPercent, A4_WIDTH_MM)),
                height: Math.round(convertPercentToMm(el.heightPercent, A4_HEIGHT_MM)),
              })),
              stamps: showObj.stamp.map((el) => ({
                page: el.page,
                offset: {
                  x: Math.round(convertPercentToMm(el.xPercent, A4_WIDTH_MM)),
                  y: Math.round(convertPercentToMm(el.yPercent, A4_HEIGHT_MM)),
                },
                width: Math.round(convertPercentToMm(el.widthPercent, A4_WIDTH_MM)),
                height: Math.round(convertPercentToMm(el.heightPercent, A4_HEIGHT_MM)),
              })),
            },
          })
            .then(() => {
              toast('Документ успешно сохранён', { type: 'success' });
              setTimeout(() => {
                queryClient.invalidateQueries();
                navigate(DOCS_PATH);
              }, 2500);
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error('Error:', error));
  };

  // useEffect(() => {
  //   console.log('showObj', showObj);
  //   console.log('showObj CONV:', {
  //     id: doc?.id,
  //     data: {
  //       signs: showObj.signature.map((el) => ({
  //         page: el.page,
  //         offset: {
  //           x: Math.round(convertPercentToMm(el.xPercent, A4_WIDTH_MM)),
  //           y: Math.round(convertPercentToMm(el.yPercent, A4_HEIGHT_MM)),
  //         },
  //         width: Math.round(convertPercentToMm(el.widthPercent, A4_WIDTH_MM)),
  //         height: Math.round(convertPercentToMm(el.heightPercent, A4_HEIGHT_MM)),
  //       })),
  //       stamps: showObj.stamp.map((el) => ({
  //         page: el.page,
  //         offset: {
  //           x: Math.round(convertPercentToMm(el.xPercent, A4_WIDTH_MM)),
  //           y: Math.round(convertPercentToMm(el.yPercent, A4_HEIGHT_MM)),
  //         },
  //         width: Math.round(convertPercentToMm(el.widthPercent, A4_WIDTH_MM)),
  //         height: Math.round(convertPercentToMm(el.heightPercent, A4_HEIGHT_MM)),
  //       })),
  //     },
  //   });
  // }, [showObj]);

  const onLoad = (e: any) => {
    const file = e?.target?.files?.[0];

    if (!file) {
      toast('Ошибка загрузки файла', { type: 'error' });
      return;
    }

    if (!type) {
      toast('Укажите тип документа', { type: 'error' });
      return;
    }

    if (file.size > MAX_PDF_SIZE_MB * 1_000_000) {
      toast(`Размер файла не должен превышать ${MAX_PDF_SIZE_MB} МБ`, { type: 'error' });
      return;
    }

    setSelectedFile(file);
    // setDoc(file);

    const formData = new FormData();
    formData.append('name', name || e?.target?.files?.[0]?.name?.replace(/.pdf/i, ''));
    formData.append('file', e?.target?.files?.[0]);
    formData.append('type', type);
    if (customerId) formData.append('customerId', customerId);
    if (parentContract) {
      formData.append('parentId', parentContract.toString());
    }
    const number = form.getFieldValue('number');
    if (number) formData.append('number', number);

    createDoc(formData)
      .then((res: any) => {
        if (res?.name) {
          if (!form.getFieldValue('title')) {
            form.setFieldValue('title', res.name?.replace(/.pdf/, ''));
          }
          setDoc(res);
        }
      })
      .catch((error: any) => {
        console.log('ERROR:', error);
      })
      .finally(() => {
        setSelectedFile('');
      });
  };

  const onResize = useCallback<ResizeObserverCallback>(
    (entries) => {
      const [entry] = entries;
      if (entry) {
        if (!containerWidth) setContainerWidth(entry.contentRect.width);
        if (!containerHeight) setContainerHeight(entry.contentRect.height);
      }
    },
    [containerHeight, containerWidth],
  );

  useResizeObserver(document.getElementById('root'), {}, onResize);

  const onDocumentLoadSuccess = (props: any) => {
    setTimeout(() => {
      if (docRef.current) {
        const page: HTMLElement = docRef.current?.pages?.current?.[0];
        const pageBox: DOMRect | undefined = page?.getBoundingClientRect();
        if (pageBox?.width && pageBox.height) {
          setContainerWidth(pageBox.width);
          setContainerHeight(pageBox.height);
        }
        if (page) setContainerRef(page);
      }
    }, 222);
    setNumPages(props?.numPages);
  };

  useEffect(() => {
    const docEl = document.querySelector('.react-pdf__Page');
    if (docEl) {
      docEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [showObj.signature.length, showObj.stamp.length]);

  return (
    <div className="my-4 mx-[50px] flex flex-col">
      <div className="w-full flex justify-between mb-4">
        <h1 className="text-[20px]">Загрузка документа</h1>
        <div className="flex gap-4">
          {!doc ? (
            <Button type="primary" className="w-fit" disabled={!type || !client}>
              <Input
                className="absolute w-full h-full left-0 top-0 opacity-0"
                type="file"
                accept=".pdf"
                onChange={onLoad}
                key={selectedFile ? selectedFile.name : ''}
              />
              Загрузить файл
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleSave}
              // disabled={!showObj.signature.length || !showObj.stamp.length || !client || !docType}
              disabled={
                !client ||
                !showObj.signature.length ||
                (companyType && isIP && !showObj.stamp.length) ||
                !docType
              }
            >
              Сохранить документ
            </Button>
          )}
        </div>
      </div>{' '}
      <Block className="bg-white">
        <div className="w-full flex justify-between items-center mb-2">
          <Form
            form={form}
            name="basic"
            initialValues={{ title: '', client: '', doctype: '' }}
            className="flex w-full justify-between"
            layout={'vertical'}
          >
            <div className="flex gap-4">
              <Form.Item name="client" label="Клиент" rules={[{ required: true }]}>
                <Dropdown
                  disabled={doc || (extId && client?.id)}
                  menu={{
                    items: (Array.isArray(clientsAA) ? clientsAA : []).map((el: any) => ({
                      label: el.name,
                      key: el.id,
                    })),
                    onClick: handleSelect,
                  }}
                  trigger={['click']}
                >
                  <a
                    tabIndex={0}
                    onClick={(e) => e.preventDefault()}
                    className="rounded-[6px] border px-4 py-1 items-center h-8 inline-flex"
                    style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                  >
                    <div className="inline-flex items-center justify-between w-full gap-4 min-w-[16rem]">
                      {client?.name || 'Выберите клиента'}
                      <DownOutlined />
                    </div>
                  </a>
                </Dropdown>
              </Form.Item>
              <div
                className={clsx(
                  (type === DOC_TYPES[1].type || type === DOC_TYPES[2].type) && ' px-4 rounded-2xl',
                )}
              >
                <Form.Item name="doctype" label="Тип документа" rules={[{ required: true }]}>
                  <Dropdown
                    className="bg-white"
                    disabled={doc}
                    menu={{
                      items: DOC_TYPES.map((el: any) => ({
                        label: el.name,
                        key: el.type,
                      })),
                      onClick: handleSelectDocType,
                    }}
                    trigger={['click']}
                  >
                    <a
                      tabIndex={0}
                      onClick={(e) => e.preventDefault()}
                      className="rounded-[6px] border px-4 py-1 items-center h-8 inline-flex"
                      style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                    >
                      <div className="inline-flex items-center justify-between w-full gap-4 min-w-[15rem]">
                        {docType?.name || 'Выберите тип документа'}
                        <DownOutlined />
                      </div>
                    </a>
                  </Dropdown>
                </Form.Item>
                {(type === DOC_TYPES[1].type || type === DOC_TYPES[2].type) && (
                  <>
                    <Form.Item name="parentId" label="К договору" rules={[{ required: true }]}>
                      <Dropdown
                        className="bg-white max-w-[100%]"
                        menu={{
                          items: contracts?.map((el: any) => ({
                            label: el.name,
                            key: el.id,
                          })),
                          onClick: handleSelectParent,
                        }}
                        trigger={['click']}
                      >
                        <a
                          tabIndex={0}
                          onClick={(e) => e.preventDefault()}
                          className="rounded-[6px] border px-4 py-1 items-center h-8 inline-flex"
                          style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}
                        >
                          <div className="inline-flex items-center justify-between w-full gap-4 min-w-[15rem]">
                            {contracts?.find((el: any) => +el.id === parentContract)?.name ||
                              'Выберите документ'}
                            <DownOutlined />
                          </div>
                        </a>
                      </Dropdown>
                    </Form.Item>
                    <Form.Item
                      name="number"
                      label="Номер"
                      rules={[
                        {
                          required: true,
                          pattern: /^\d{1,3}$/,
                          message: 'Может содержать от 1 до 3 цифр',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                )}
              </div>
              <Form.Item
                name="title"
                label="Название документа"
                // rules={[{ required: true, message: 'Введите название документа' }]}
              >
                <Input className="min-w-[360px]" disabled={doc} />
              </Form.Item>
            </div>
          </Form>

          {doc && (
            <BlueBox
              className={clsx(
                '!w-fit !h-fit transition-all',
                !showObj.signature.length || (companyType && !isPhysical && !showObj.stamp.length)
                  ? 'opacity-100'
                  : 'opacity-0',
              )}
              text={`Установите место для ${
                companyType && !isPhysical
                  ? ` печати ${companyType && isIndividual && '(опционально)'} и `
                  : ''
              } подписи документа`}
            />
          )}
        </div>
        {doc && (
          <Block className="bg-[#F5F5F5] min-h-[10vh]" noShadow>
            <div className="flex gap-4 absolute top-5 left-5">
              <Button
                className="bg-white"
                onClick={() => {
                  setShowObj((prev) => {
                    const arr = [...prev.signature];
                    arr.push({ id: uid(), page });
                    return { ...prev, signature: arr };
                  });
                }}
              >
                Место подписи
              </Button>
              <Button
                id="btn-stamp"
                className={clsx('bg-white', isPhysical && 'hidden')}
                onClick={() =>
                  setShowObj((prev) => {
                    const arr = [...prev.stamp];
                    arr.push({ id: uid(), page });
                    return { ...prev, stamp: arr };
                  })
                }
              >
                Место печати
              </Button>
            </div>
            {doc && (
              <div
                className={clsx('w-full !overflow-auto h-fit min-h-[10vh] max-h-[65vh]', css.doc)}
              >
                <Document
                  ref={docRef}
                  className={'w-fit mx-auto relative'}
                  file={`${BASE_URL}${doc?.signedLink || doc?.link}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(e) => console.error(e)}
                  loading={<div className="my-4">Загрузка PDF...</div>}
                  error={<div className="my-4">Ошибка загрузки PDF</div>}
                >
                  {showObj?.signature?.map(
                    (el: SignType) =>
                      el.page === page && (
                        <DD
                          key={el.id}
                          data={el}
                          setShowObj={setShowObj}
                          aspectRatio={dataClient?.signatureAspectRatio || 2}
                          type="signature"
                          containerWidth={
                            containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                          }
                          containerHeight={containerHeight}
                        />
                      ),
                  )}
                  {showObj?.stamp?.map(
                    (el: SignType) =>
                      el.page === page && (
                        <DD
                          key={el.id}
                          data={el}
                          setShowObj={setShowObj}
                          aspectRatio={dataClient?.stampAspectRatio || 1}
                          type="stamp"
                          containerWidth={
                            containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                          }
                          containerHeight={containerHeight}
                        />
                      ),
                  )}

                  <Page
                    key={`page_${page}`}
                    pageNumber={page}
                    width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                    loading={null}
                  />
                </Document>
                {numPages > 0 && (
                  <Pagination
                    className="mt-2 ml-auto w-fit"
                    current={page}
                    pageSize={1}
                    total={numPages}
                    onChange={(page) => setPage(page)}
                    hideOnSinglePage
                    showSizeChanger={false}
                  />
                )}
              </div>
            )}
          </Block>
        )}
      </Block>
    </div>
  );
}
